<template>
	<div class="mt-3">
		<b-row>
			<b-col sm="12" class="my-2">
				<i class="icon-layers"></i>&nbsp;
				<span class="header">ASSET LIST</span>
			</b-col>
		</b-row>

		<b-row class="my-2">
			<b-col sm="6" class="my-2">
				<i class="fa fa-filter"></i>&nbsp;
				<b>FILTER OPTIONS</b>
			</b-col>
			<b-col sm="6" class="my-2" align="right">
				<div v-show="!showFilterOptions" size="sm" @click="toggleFilterOptions"
					v-b-tooltip.hover.top="'Show/Hide Filter Options'">
					<i class="icon-arrow-down"></i>
				</div>
				<div v-show="showFilterOptions" size="sm" @click="toggleFilterOptions"
					v-b-tooltip.hover.top="'Show/Hide Filter Options'">
					<i class="icon-arrow-up"></i>
				</div>
			</b-col>
		</b-row>
		<b-row class="mt-2 mb-4" v-show="showFilterOptions">
			<b-col md="3" sm="3" class="mb-2" align="right">
				<b>Asset Type</b>
			</b-col>
			<b-col md="8" sm="8" class="mb-2">
				<b-form-select name="Asset Type" v-model="filterBy.assetType" :options="allAssetTypesOptions" />
			</b-col>
			<b-col md="3" sm="3" class="mb-2" align="right">
				<b>Asset Code</b>
			</b-col>
			<b-col md="8" sm="8" class="mb-2">
				<b-form-input name="Asset Code" type="text" v-model="filterBy.assetCode" maxlength="25" />
			</b-col>
			<b-col md="12" sm="12" align="Right">
				<b-button variant="primary" @click="reset">Reset</b-button>&nbsp;
				<b-button variant="success" @click="search">Search</b-button>
			</b-col>
		</b-row>

		<b-row class="my-2">
			<b-col sm="5" class="my-1 text-sm-left">
				<b-button size="sm" @click="selectAllRows">Select all</b-button>&nbsp;&nbsp;
				<b-button size="sm" @click="clearSelected">Clear</b-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			</b-col>
			<b-col sm="5" class="my-1 text-sm-left">
				<span class="totalDisplay">Selected: {{ Object.keys(selected).length }}</span>
				&nbsp;&nbsp;|&nbsp;&nbsp;
				<span class="totalDisplay">Total: {{ totalRows }}</span>
			</b-col>
			<b-col sm="2" class="my-1 text-sm-right">
				<b-button variant="success" @click="addSelectedAssets" v-b-tooltip.hover.top="'Add Selected Assets'"
					class="mr-1">
					<i class="fa fa-plus"></i>
				</b-button>
			</b-col>
		</b-row>

		<b-row class="mx-1">
			<b-table ref="assetsTable" show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
				:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
				:sort-direction="sortDirection" responsive selectable select-mode="multi" @row-selected="onRowSelected"
				selected-variant="primary">
				<template v-slot:cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>
			</b-table>
		</b-row>
		<b-row>
			<b-col sm="6" class="text-sm-right">
				<b-input-group prepend="Show" append="/ Page" size="sm">
					<b-form-select :options="pageOptions" v-model="perPage" />
				</b-input-group>
			</b-col>
			<b-col sm="6">
				<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" size="sm" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Util
import { SortUtil } from '@/utils/sortUtil';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import _ from 'lodash';

export default {
	name: 'asset-list-table',
	props: {
		selAssetIds: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'selected',
					label: '#',
					sortable: false,
				},
				{
					key: 'assetCode',
					label: 'Code',
					sortable: true,
				},
				{
					key: 'assetType',
					label: 'Type',
					sortable: true,
				},
				{
					key: 'status',
					label: 'Status',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// for filtering
			filterBy: {
				assetType: config.assetTypeDefaultValue,
				status: 'Stored',
				assetCode: '',
			},
			selSourceLocation: {},
			statusOptions: [
				{
					value: 'Inactive',
					text: 'Inactive',
				},
				{
					value: 'Stored',
					text: 'Stored',
				},
				{
					value: 'Lost',
					text: 'Lost',
				},
			],

			// for selected fields
			selected: {},
			allAssetsOptions: [],
			alreadyDispatchedAssets: {},

			allAssetTypesOptions: [],
			allDispatchesObj: {},
			showFilterOptions: false,
		};
	},
	watch: {
		allAssetsOptions: function () {
			this.items = this.allAssetsOptions;
			this.totalRows = this.items.length;
		},
		allDispatchesObj: {
			handler() {
				_.forEach(this.allDispatchesObj, (dispatch) => {
					if (
						dispatch.status === 'Draft' ||
						dispatch.status === 'In-Transit' ||
						dispatch.status === 'Receiving'
					) {
						let assetsArr = dispatch.assets;
						assetsArr.forEach((assetCode) => {
							this.alreadyDispatchedAssets[assetCode] = dispatch.dispatchId;
						});
					}
				});
			},
			deep: true,
		},
	},
	mounted() {
		EventBus.$on('resetAssetListFilter', () => {
			this.reset();
		});

		EventBus.$on('onChangeSelSourceLocation', async (param) => {
			this.selSourceLocation = param.selSourceLocation;

			// Needs to integrate asset type to other asset type
			this.allAssetTypesOptions = param.allAssetTypesOptions;
			this.allDispatchesObj = param.allDispatchesObj;

			await this.search();
		});

		EventBus.$on('onDeleteAsset', (deletedAsset) => {
			let assetCodesArr = _.map(this.items, 'assetCode');
			if (!_.includes(assetCodesArr, deletedAsset.assetCode)) {
				this.items.push(deletedAsset);
				this.items = SortUtil.sortByAttr('assetCode', this.items);
				this.totalRows = this.items.length;
			}
		});
	},
	methods: {
		reset() {
			this.filterBy.assetType = config.assetTypeDefaultValue;
			this.filterBy.status = 'Stored';
			this.filterBy.assetCode = '';
		},
		async search() {
			/** TODO: Need Optimization with the retrieval of Assets Here */
			try {
				let results = await this.$store.dispatch('getAssetsByStatus', {
					status: this.filterBy.status,
					assetType: this.filterBy.assetType.name,
					assetCode: this.filterBy.assetCode,
					companyId: this.selSourceLocation.companyId,
					storageLocationId: this.selSourceLocation.id,
				});

				this.retrieveAssetOptions(results);
			} catch (error) {
				console.log(error);
				this.$toaster.warning(
					'Error loading initial assets from the selected source storage location'
				);
			}
		},
		onRowSelected(items) {
			this.selected = items;
		},
		selectAllRows() {
			this.$refs.assetsTable.selectAllRows();
		},
		clearSelected() {
			this.$refs.assetsTable.clearSelected();
		},
		toggleFilterOptions() {
			this.showFilterOptions = !this.showFilterOptions;
		},
		addSelectedAssets() {
			let selAssetsArr = Object.values(this.selected);

			if (selAssetsArr.length === 0) {
				this.$toaster.warning(
					'Please select the assets you want to include for this dispatch.'
				);
			} else {
				EventBus.$emit('addSelectedAssets', selAssetsArr);
				// Remove the transported assets from the list of available assets
				this.removeSelectedAssets(selAssetsArr);
			}
		},
		removeSelectedAssets(selAssetsArr) {
			let transportedAssetsOptions = [];
			selAssetsArr.forEach((asset) => {
				transportedAssetsOptions.push(asset.id);
			});

			this.items = this.items.filter(function (value, _index, _arr) {
				return !transportedAssetsOptions.includes(value.id);
			});
			this.totalRows = this.items.length;
		},
		retrieveAssetOptions(assets) {
			// show list of available assets for dispatch
			this.allAssetsOptions = [];

			_.forEach(assets, (asset) => {
				if (
					!this.alreadyDispatchedAssets[asset.assetCode] &&
					this.selAssetIds &&
					!this.selAssetIds.includes(asset.id)
				) {
					this.allAssetsOptions.push({
						id: asset.id,
						assetCode: asset.assetCode,
						assetType: asset.assetType,
						assetTypeId: asset.assetTypeId,
						company: asset.currCompany,
						companyId: asset.currCompanyId,
						status: asset.status,
					});
				}
			});
		},
	},
	beforeDestroy() {
		EventBus.$off('resetAssetListFilter');
		EventBus.$off('onChangeSelSourceLocation');
		EventBus.$off('onDeleteAsset');
	},
};
</script>

<style scoped></style>
